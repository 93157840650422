<template>
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="close"
    :url-list="imgSrcList"
  />
</template>

<script setup>
import { ref } from 'vue'

const showImagePreview = ref(false)
const imgSrcList = ref([])

function show(imgSrcs) {
  showImagePreview.value = true
  imgSrcList.value = imgSrcs
}

function close () {
  showImagePreview.value = false
  imgSrcList.value = []
}

defineExpose({
  show
})
</script>

<style></style>
