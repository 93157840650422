<template>
  <el-dialog
    v-model="dialogVisible"
    :title="currentRow?.name + '票据上传'"
    width="1126"
    custom-class="ocr-img-dialog"
    @closed="destroyOcrImg"
    @opened="addOcrImg"
  >
    <div ref="contentBoxRef" class="content"></div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="jsx" setup>
import { ref, createApp } from 'vue'

import OcrImg from './ocr-img/OcrImg'

import { getSocketInstance, clearTimer, setIsManualCloseSocket } from './ocr-img/utils'

const contentBoxRef = ref()
const dialogVisible = ref(false)
const ocrImgInstance = ref()
const currentRow = ref()

function init(row) {
  currentRow.value = row
  dialogVisible.value = true
}

function addOcrImg() {
  setIsManualCloseSocket(false)
  ocrImgInstance.value = createApp({
    render() {
      return <OcrImg comId={currentRow.value.comId} period={currentRow.value.period} />
    }
  })
  ocrImgInstance.value.mount(contentBoxRef.value)
}
function destroyOcrImg() {
  currentRow.value = undefined
  const { socket } = getSocketInstance()
  clearTimer()
  setIsManualCloseSocket(true)
  socket?.close()
  ocrImgInstance.value?.unmount()
}

defineExpose({
  init
})
</script>

<style lang="scss" scoped>
.content {
  height: 630px;
}
</style>
<style>
.ocr-img-dialog .el-dialog__body {
  padding-bottom: 10px;
}
</style>