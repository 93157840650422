<template>
  <el-drawer
    class="ocr-upload-list"
    :title="innerRow?.name"
    v-model="showDrawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="70%"
  >
    <div style="margin-bottom:10px">
      <qzf-search-period v-model:period="query.period"></qzf-search-period>
      <el-select v-model="query.voucherType" class="w-100" placeholder="请选择类型" size="small">
        <el-option v-for="item in options" :key="item.value" :value="item.value" :label="item.label" />
      </el-select>
      <el-select v-if="query.voucherType === 1" v-model="query.markType" class="w-130" placeholder="请选择票据类型" size="small">
        <el-option v-for="item in typeOptions" :key="item.value" :value="item.value" :label="item.label" />
      </el-select>
      <el-select class="w-160" size="small" v-model="query.isVouchered" placeholder="请选择是否已生成凭据" clearable>
        <el-option :value="'1'" label="是" />
        <el-option :value="'2'" label="否" />
      </el-select>
      <el-button size="small" type="primary" @click="getList()">搜索</el-button>
    </div>
    <el-table :data="showList" border :height="tableHeight" style="width: 100%" stripe v-loading="loading" @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <!-- <el-table-column type="selection" align="center" width="55" :selectable="selectable" v-if="showStatus"/> -->
      <el-table-column
        prop="period"
        label="类型"
        min-width="100"
        align="center"
      >
        <template #default="scope">
          {{ uploadTypeEnum[scope.row.type] }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="mark_type"
        label="票据类型"
        min-width="100"
        align="center"
      /> -->
      <el-table-column
        prop="billDate"
        label="单据日期"
        min-width="100"
        align="center"
      />
      <el-table-column prop="url_img" label="图片附件" min-width="200" align="center">
        <template #default="scope">
          <div class="text-ellipsis">
            <el-button type="primary" link @click="() => open(scope.row.url_img)">
              <el-icon style="color: #17a2b8;margin-right: 3px;"><Folder /></el-icon>
              预览
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center" fixed="right"> 
        <template #default="scope">
          <el-button :disabled="scope.row.voucher_id !== 0" type="text" size="small" @click="() => delFile([scope.row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="query.page" v-model:limit="query.limit" @pagination="getList" />
    </div>
  </el-drawer>
  <ImgPreview ref="imagePreviewRef" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Folder } from '@element-plus/icons-vue'

import { voucherQueryWithLimit } from '@/api/voucher.js'
import { delBill } from '@/api/company.js'
import { imgUploadOptions, uploadTypeEnum, typeOptions } from './constant'

import ImgPreview from './ImgPreview'

const { proxy } = getCurrentInstance()
const originQuery = {
  isVouchered: '2',
  voucherType: '0',
  markType: '',
  period: '',
  comId: '',
  page: 0,
  limit: 10,
}
const query = ref({...originQuery})
const showList = ref([])
const selections = ref([])
const imagePreviewRef = ref()
const showDrawer = ref(false)
const innerRow = ref()
const total = ref(0)
const loading = ref(false)
const tableHeight = ref()
const options = [
{ label: '全部', value: '0' },
...imgUploadOptions
]

onMounted(() => {
  tableHeight.value = proxy.$getHeight(250)
})

function open(row) {
  imagePreviewRef.value?.show([row])
}

function show(row) {
  showDrawer.value = true
  innerRow.value = row
  query.value = {
    ...originQuery,
    comId: row.comId,
    period: row.period,
  }
  getList()
}

function delFile(billId) {
  ElMessageBox.confirm(
    '确认删除么',
    '删除',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      beforeClose: (action, instance, done) => {
        if (action !== 'confirm') return done()
        instance.confirmButtonLoading = true;
        delBill({ billId })
          .then(res => {
            if (res.data.msg == 'success'){
              ElMessage.success('删除成功')
              getList()
              done()
            }
          })
          .finall(() => {
            instance.confirmButtonLoading = true;
          })
      }
    }
  )
}

function getList() {
  loading.value = true
  voucherQueryWithLimit({
    ...query.value,
    voucherType: +query.value.voucherType
  }).then(res=>{
    loading.value = false
    if(res.data.msg == 'success'){
      showList.value = res.data.data.list || []
      total.value = res.data.data.total
    }
  })
}

function handleSelectionChange(rows) {
  selections.value = rows
}

function handleClose() {
  query.value = {...originQuery}
  total.value = 0
  showDrawer.value = false
}

defineExpose({
  show,
})
</script>

<style scoped lang="scss">
.w-100 {
  width: 100px;
}
.w-160 {
  width: 160px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
