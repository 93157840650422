let socket;
let isSocketConnect = false;

let MainCanvas;
let MainContext;

let pMainShowStartX = 0;
let pMainShowStartY = 0;

let isMouseDown = false;
let pALastX = 0;
let pALastY = 0;
let pACurrentX = 0;
let pACurrentY = 0;
let imgRealPath = ''

let MainCamCutMode = 0;
let isManualCloseSocket = false

function releaseSocketPro() {
  if (isSocketConnect) {
    let aDataArray = new Uint8Array(3);
    aDataArray[0] = 0x77;
    aDataArray[1] = 0x88;
    aDataArray[2] = 0xFF;
    socket.send(aDataArray.buffer);
  }
}


window.onbeforeunload = function () {

    CloseCamera();  //关闭摄像头
    try {
        releaseSocketPro();
        socket.close();
        socket = null;
    } catch (ex) {
      console.log(ex)
    }
    console.log("onbeforeunload");

};


function $(id) {
    return document.getElementById(id);
}


function toSleep(milliSeconds) {
    let startTime = new Date().getTime();
    while (new Date().getTime() < startTime + milliSeconds);
}

function Uint8ArrayToString(fileData) {
    let dataString = "";
    for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
    }
    return dataString
}


function stringToByte(str) {
    let bytes = new Array();
    let len, c;
    len = str.length;
    for (let i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x010000 && c <= 0x10FFFF) {
            bytes.push(((c >> 18) & 0x07) | 0xF0);
            bytes.push(((c >> 12) & 0x3F) | 0x80);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000800 && c <= 0x00FFFF) {
            bytes.push(((c >> 12) & 0x0F) | 0xE0);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000080 && c <= 0x0007FF) {
            bytes.push(((c >> 6) & 0x1F) | 0xC0);
            bytes.push((c & 0x3F) | 0x80);
        } else {
            bytes.push(c & 0xFF);
        }
    }
    return bytes;
}

function byteToString(arr) {
    if (typeof arr === 'string') {
        return arr;
    }
    let str = '',
            _arr = arr;
    for (let i = 0; i < _arr.length; i++) {
        let one = _arr[i].toString(2),
                v = one.match(/^1+?(?=0)/);
        if (v && one.length == 8) {
            let bytesLength = v[0].length;
            let store = _arr[i].toString(2).slice(7 - bytesLength);
            for (let st = 1; st < bytesLength; st++) {
                store += _arr[st + i].toString(2).slice(2);
            }
            str += String.fromCharCode(parseInt(store, 2));
            i += bytesLength - 1;
        } else {
            str += String.fromCharCode(_arr[i]);
        }
    }
    return str;
}

function addEvent(obj, xEvent, fn) {
    if (obj.attachEvent) {
        obj.attachEvent('on' + xEvent, fn);
    } else {
        obj.addEventListener(xEvent, fn, false);
    }
}


export function InitCanvas(DivMainBox, mX, mY, mwidth, mheight) {

    //let DivMainBox = $("CameraCtl");

    if (mwidth != 0 && mheight != 0) {
        MainCanvas = document.createElement("canvas");
        // MainCanvas.style.border = "solid 1px #A0A0A0";
        MainCanvas.id = "MainCamCanvas";
        MainCanvas.width = mwidth;
        MainCanvas.height = mheight;
        MainContext = MainCanvas.getContext("2d");
        DivMainBox.appendChild(MainCanvas);      //添加画布
        MainCanvas.onmousedown = MainCanvasMouseDown;
        MainCanvas.onmousemove = MainCanvasMouseMove;
        MainCanvas.onmouseup = MainCanvasMouseUp;
        MainCanvas.onmouseout = MainCanvasMouseOut;
        addEvent(MainCanvas, 'mousewheel', onMouseWheel);
        addEvent(MainCanvas, 'DOMMouseScroll', onMouseWheel);

    }

}

//*************摄像头操作初始化***************
function Cam_ControlInit(documentObj, mX, mY, mwidth, mheight, getResultFuncObj) {
    WebSocketConnect(getResultFuncObj);
    InitCanvas(documentObj, mX, mY, mwidth, mheight);
    //console.log("Cam_ControlInit");
}


//*************获取设备数目***************
function Cam_GetDevCount() {
    GetDevCount();
}

//***************获取分辨率***************
function Cam_GetDevResolution(iCamNo) {
    GetResolution(iCamNo);
}

//*************打开主摄像头***************
 function Cam_Open(iCamNo, width, height) {
     OpenCamera(iCamNo, width, height);
 }

 //*************关闭摄像头***************
 function Cam_Close() {
     CloseCamera();  
 }


 //*************拍照***************
 function Cam_Photo(fileAddr) {
      if (MainCamCutMode == 2) {
          let rectx, recty, rectw, recth;
          if (pALastX > pACurrentX)
              rectx = pACurrentX;
          else
              rectx = pALastX;
          if (pALastY > pACurrentY)
              recty = pACurrentY;
          else
              recty = pALastY;
          rectw = Math.abs(pACurrentX - pALastX);
          recth = Math.abs(pACurrentY - pALastY);

          SetCutRect(rectx, recty, rectw, recth);  //手动裁剪区域
      }    
     CaptureImage(fileAddr);
 }

//*************放大***************
 export function Cam_ZoomIn() {
    ZoomIn();
}

//*************缩小***************
export function Cam_ZoomOut() {
    ZoomOut();
}

//*************适合大小***************
export function Cam_BestSize() {
    BestSize();
}

//*************1:1***************
export function Cam_TrueSize() {
    TrueSize();
}

//*************旋转***************
export function Cam_Rotate(angle) {
    Rotate(angle);
}

//*************左旋***************
export function Cam_RotateLeft() {
    RotateL();
}

//*************右旋***************
export function Cam_RotateRight() {
    RotateR();
}

//*************对焦***************
export function Cam_Focus() {
    ManualFocus();
}

//*************设置视频属性***************
export function Cam_ShowVideoProp() {
    ShowVideoProp();
}

//*************设置裁剪模式***************
export function Cam_SetCutMode( CutMode) {
    SetCutMode(CutMode);
}


//*************读取身份证信息*************
export function Cam_ReadIdCard(cardImgPath) {
    ReadIdCard(cardImgPath);
}


//*************读取身份证信息并生成正反面合并证件照*************
export function Cam_ReadIdCardEx(cardImgPath) {
    ReadIdCardEx(cardImgPath);
}

//*************上传文件*************
export function UploadFile(url, filepath) {
    toUploadFile(url, filepath);
}

export function UploadFileEx(lpServer, port, lpAddr, filepath) {
    toUploadFileEx(lpServer, lpAddr, port, filepath);
}


//*************设置文件格式*************
export function Cam_SetFileType(filetype) {
    SetFileType(filetype);
}

//*************设置JPG图片质量*************
export function Cam_SetJpgQuality(val) {
    SetJpgQuality(val);
}


//*************设置色彩模式*************
export function Cam_SetColorMode(colorMode) {
    SetColorMode(colorMode);
}

//*************设置去黑边*************
export function Cam_SetDeleteBlackEdge(flag) {
    SetDeleteBlackEdge(flag);
}

//*************设置去底色*************
export function Cam_SetDeleteBgColor(flag) {
    SetDeleteBgColor(flag);
}

//*************从摄像头中识别二维码条码*************
export function Cam_RecogQrBarCodeFromCamera(type) {
    RecogQrBarCodeFromCamera(type);
}

//*************从图片文件中识别二维码条码*************
export function Cam_RecogQrBarCodeFromFile(type,filePath) {
    RecogQrBarCodeFromFile(type, filePath);
}


//*************添加要合并的图片到PDF*************
export function Cam_AddImgFileToPDF(filePath) {
    AddImgFileToPDF(filePath);
}

//*************添加要合并PDF的图片路径集合*************
export function Cam_AddImgFileToPDFex(filePath) {
    AddImgFileToPDFex(filePath);
}

//*************合并PDF*************
export function Cam_CombinePDF(filePath) {
    CombinePDF(filePath);
}


//*************添加要合并的图片*************
export function Cam_AddMergeImageFile(filePath) {
    AddMergeImageFile(filePath);
}

//*************合并图像*************
export function Cam_MergeImages(filePath,dir) {
    MergeImages(filePath, dir);
}

//*************获取驱动盘符*************
export function GetDrives() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(3);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA6;
        socket.send(aDataArray.buffer);
    }
}

//*************设置水印*************
export function SetWaterMark(isAdd, wType, addTime, wTransp, wPos, wSize, wColor, szInfo) {
    if (isSocketConnect) {
        if (szInfo == "")
            return;
        let info = encodeURI(szInfo);
        //console.log(info);
        let infoArray = stringToByte(info);
        let len = infoArray.length;
        let totalLen = len + 10;
        let aDataArray = new Uint8Array(totalLen);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA7;
        aDataArray[3] = isAdd;
        aDataArray[4] = wType;
        aDataArray[5] = addTime;
        aDataArray[6] = wPos;
        aDataArray[7] = wSize;
        aDataArray[8] = wTransp;
        aDataArray[9] = wColor;
        for (let i = 0; i < len; i++) {
            aDataArray[10 + i] = infoArray[i];
        }
        socket.send(aDataArray.buffer);
    }
}

function DeleteFile(filePath) {
    if (isSocketConnect) {
        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 11;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xA8;
            aDataArray[3] = len >> 16 & 0xff;
            aDataArray[4] = len >> 8 & 0xff;
            aDataArray[5] = len & 0xff;
            aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[7] = packageCount & 0xff;   //包总数
            aDataArray[8] = 0;   //分包长度
            aDataArray[9] = pindex >> 8 & 0xff;   //包序号
            aDataArray[10] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                  let totalLen = 90 + 11;
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xA8;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                  let totalLen = 90 + tmpLen + 11;  // 此时tmpLen为负数，做加法运算
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xA8;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90 + tmpLen;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(80);
            }
        }

    }
}

//开始录像
function StartRecord(filePath,fps) {
    if (isSocketConnect) {
        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 12;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xF0;
            aDataArray[3] = fps;
            aDataArray[4] = len >> 16 & 0xff;
            aDataArray[5] = len >> 8 & 0xff;
            aDataArray[6] = len & 0xff;
            aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[8] = packageCount & 0xff;   //包总数
            aDataArray[9] = 0;   //分包长度
            aDataArray[10] = pindex >> 8 & 0xff;   //包序号
            aDataArray[11] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            //console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                  let totalLen = 90 + 12;
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xF0;
                    aDataArray[3] = fps;
                    aDataArray[4] = len >> 16 & 0xff;
                    aDataArray[5] = len >> 8 & 0xff;
                    aDataArray[6] = len & 0xff;
                    aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[8] = packageCount & 0xff;   //包总数
                    aDataArray[9] = 90;   //分包长度
                    aDataArray[10] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[11] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[12 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                  let totalLen = 90 + tmpLen + 12;  // 此时tmpLen为负数，做加法运算
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xF0;
                    aDataArray[3] = fps;
                    aDataArray[4] = len >> 16 & 0xff;
                    aDataArray[5] = len >> 8 & 0xff;
                    aDataArray[6] = len & 0xff;
                    aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[8] = packageCount & 0xff;   //包总数
                    aDataArray[9] = 90 + tmpLen;   //分包长度
                    aDataArray[10] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[11] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[12 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(80);
            }
        }

    }
}


//停止录像
function StopRecord() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(3);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xF1;
        socket.send(aDataArray.buffer);
    }
 }



function CombinePDF(filePath) {
    if (isSocketConnect) {


//        if (filePath == "") {
//            let len = 0;
//            let aDataArray = new Uint8Array(3);
//            aDataArray[0] = 0x77;
//            aDataArray[1] = 0x88;
//            aDataArray[2] = 0x32;
//            for (let i = 0; i < len; i++) {
//                aDataArray[3 + i] = pathArray[i];
//            }
//            socket.send(aDataArray.buffer);
//        }
//        else {
//            let path = encodeURI(filePath);
//            let pathArray = stringToByte(path);
//            let len = pathArray.length;
//            let totalLen = len + 3;
//            let aDataArray = new Uint8Array(totalLen);
//            aDataArray[0] = 0x77;
//            aDataArray[1] = 0x88;
//            aDataArray[2] = 0x32;
//            for (let i = 0; i < len; i++) {
//                aDataArray[3 + i] = pathArray[i];
//            }
//            socket.send(aDataArray.buffer);
        //        }       


        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 11;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x32;
            aDataArray[3] = len >> 16 & 0xff;
            aDataArray[4] = len >> 8 & 0xff;
            aDataArray[5] = len & 0xff;
            aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[7] = packageCount & 0xff;   //包总数
            aDataArray[8] = 0;   //分包长度
            aDataArray[9] = pindex >> 8 & 0xff;   //包序号
            aDataArray[10] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            //console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                  let totalLen = 90 + 11;
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x32;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                  let totalLen = 90 + tmpLen + 11;  // 此时tmpLen为负数，做加法运算
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x32;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90 + tmpLen;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(80);
            }
        }

    }
 }


function AddImgFileToPDF(filePath) {
    if (isSocketConnect) {

        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 11;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x31;
            aDataArray[3] = len >> 16 & 0xff;
            aDataArray[4] = len >> 8 & 0xff;
            aDataArray[5] = len & 0xff;
            aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[7] = packageCount & 0xff;   //包总数
            aDataArray[8] = 0;   //分包长度
            aDataArray[9] = pindex >> 8 & 0xff;   //包序号
            aDataArray[10] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                  let totalLen = 90 + 11;
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x31;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                  let totalLen = 90 + tmpLen + 11;  // 此时tmpLen为负数，做加法运算
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x31;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90 + tmpLen;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(200);
            }
        }
    }
}


function AddImgFileToPDFex(filePath) {
    if (isSocketConnect) {

        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 11;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x33;
            aDataArray[3] = len >> 16 & 0xff;
            aDataArray[4] = len >> 8 & 0xff;
            aDataArray[5] = len & 0xff;
            aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[7] = packageCount & 0xff;   //包总数
            aDataArray[8] = 0;   //分包长度
            aDataArray[9] = pindex >> 8 & 0xff;   //包序号
            aDataArray[10] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                  let totalLen = 90 + 11;
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x33;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                  let totalLen = 90 + tmpLen + 11;  // 此时tmpLen为负数，做加法运算
                  let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x33;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90 + tmpLen;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(80);
            }
        }

    }
}


function AddMergeImageFile(filePath) {
    if (isSocketConnect) {
        if (filePath == "") {
            let len = 0;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xC0;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            var pathArray = stringToByte(path);
            let len = pathArray.length;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xC0;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);

        }
    }
}


function MergeImages(filePath, dir) {
    if (isSocketConnect) {
        if (filePath == "") {
            let len = 0;
            let totalLen = len + 4;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xC1;
            aDataArray[3] = dir;
            for (let i = 0; i < len; i++) {
                aDataArray[4 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            var pathArray = stringToByte(path);
            let len = pathArray.length;
            let totalLen = len + 4;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xC1;
            aDataArray[3] = dir;
            for (let i = 0; i < len; i++) {
                aDataArray[4 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);

        }
    }
 }


function RecogQrBarCodeFromCamera(type) {
    if (isSocketConnect) {
      let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA3;
        aDataArray[3] = type;
        socket.send(aDataArray.buffer);
    }
}

function RecogQrBarCodeFromFile(type, filePath) {
    if (isSocketConnect) {
        console.log(filePath);
        let path = encodeURI(filePath);
        let pathArray = stringToByte(path);
        let len = pathArray.length;
        let totalLen = len + 4;
        let aDataArray = new Uint8Array(totalLen);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA4;
        aDataArray[3] = type;
        for (let i = 0; i < len; i++) {
            aDataArray[4 + i] = pathArray[i];
        }
        socket.send(aDataArray.buffer);
    }
}


function SetFileType(filetype) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x28;
        aDataArray[3] = filetype;
        if (filetype == 1)  //png格式
            aDataArray[3] = 2;
        if (filetype == 2)  //tif格式
            aDataArray[3] = 3;
        if (filetype == 3)  //pdf格式
            aDataArray[3] = 4;
        socket.send(aDataArray.buffer);
    }
}

function SetJpgQuality(val) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x29;
        aDataArray[3] = val;
        socket.send(aDataArray.buffer);
    }
}

function SetColorMode(colorMode) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA0;
        aDataArray[3] = colorMode;
        socket.send(aDataArray.buffer);
    }
}

function SetDeleteBlackEdge(flag) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA1;
        aDataArray[3] = flag;
        socket.send(aDataArray.buffer);
    }
}

function SetDeleteBgColor(flag) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0xA2;
        aDataArray[3] = flag;
        socket.send(aDataArray.buffer);
    }
}


function toUploadFile(url, filepath) {

    if (isSocketConnect) {

        //console.log(url);
        let urlpath = encodeURI(url);
        let urlpathArray = stringToByte(urlpath);
        //console.log(urlpath);

        let imgpath = encodeURI(filepath);
        let imgpathArray = stringToByte(imgpath);

        let len = urlpathArray.length + imgpathArray.length;
        let pathDataArray = new Uint8Array(len);
        for (let i = 0; i < urlpathArray.length; i++) {
            pathDataArray[i] = urlpathArray[i];
        }
        for (let i = 0; i < imgpathArray.length; i++) {
            pathDataArray[urlpathArray.length + i] = imgpathArray[i];
        }

        let packageCount = 0;
        let tmpLen=len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;
            packageCount++;
        }

        console.log("packageCount:" + packageCount);

        let pindex = 0;
        tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;
           
            if (tmpLen > 0) {
                let totalLen = 90 + 17;
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0x90;
                aDataArray[3] = len >> 16 & 0xff;
                aDataArray[4] = len >> 8 & 0xff;
                aDataArray[5] = len & 0xff;
                aDataArray[6] = urlpathArray.length >> 16 & 0xff;
                aDataArray[7] = urlpathArray.length >> 8 & 0xff;
                aDataArray[8] = urlpathArray.length & 0xff;
                aDataArray[9] = imgpathArray.length >> 16 & 0xff;
                aDataArray[10] = imgpathArray.length >> 8 & 0xff;
                aDataArray[11] = imgpathArray.length & 0xff;
                aDataArray[12] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[13] = packageCount & 0xff;   //包总数
                aDataArray[14] = 90;   //分包长度
                aDataArray[15] = pindex >> 8 & 0xff;   //包序号
                aDataArray[16] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < 90; i++) {
                    aDataArray[17 + i] = pathDataArray[i + pindex*90];
                }
                socket.send(aDataArray.buffer);
            }
            else {
                let totalLen = 90 + tmpLen + 17;  // 此时tmpLen为负数，做加法运算
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0x90;
                aDataArray[3] = len >> 16 & 0xff;
                aDataArray[4] = len >> 8 & 0xff;
                aDataArray[5] = len & 0xff;
                aDataArray[6] = urlpathArray.length >> 16 & 0xff;
                aDataArray[7] = urlpathArray.length >> 8 & 0xff;
                aDataArray[8] = urlpathArray.length & 0xff;
                aDataArray[9] = imgpathArray.length >> 16 & 0xff;
                aDataArray[10] = imgpathArray.length >> 8 & 0xff;
                aDataArray[11] = imgpathArray.length & 0xff;
                aDataArray[12] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[13] = packageCount & 0xff;   //包总数
                aDataArray[14] = 90 + tmpLen;   //分包长度
                aDataArray[15] = pindex >> 8 & 0xff;   //包序号
                aDataArray[16] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < (90 + tmpLen); i++) {
                    aDataArray[17 + i] = pathDataArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            pindex++;
            toSleep(80);
        }

    }

}



function toUploadFileEx(lpServer, lpAddr, port, filepath) {

    if (isSocketConnect) {

        //console.log(url);
        let lpServerPath = encodeURI(lpServer);
        let lpServerArray = stringToByte(lpServerPath);
        let lpAddrPath = encodeURI(lpAddr);
        let lpAddrArray = stringToByte(lpAddrPath);
        let imgpath = encodeURI(filepath);
        let imgpathArray = stringToByte(imgpath);

        let len = lpServerArray.length + lpAddrArray.length + imgpathArray.length;

        let pathDataArray = new Uint8Array(len);
        for (let i = 0; i < lpServerArray.length; i++) {
            pathDataArray[i] = lpServerArray[i];
        }
        for (let i = 0; i < lpAddrArray.length; i++) {
            pathDataArray[lpServerArray.length + i] = lpAddrArray[i];
        }
        for (let i = 0; i < imgpathArray.length; i++) {
            pathDataArray[lpServerArray.length + lpAddrArray.length + i] = imgpathArray[i];
        }

        let packageCount = 0;
        let tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;
            packageCount++;
        }

        console.log("packageCount:" + packageCount);

        let pindex = 0;
        tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;

            if (tmpLen > 0) {
                let totalLen = 90 + 23;
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0x92;
                aDataArray[3] = len >> 16 & 0xff;
                aDataArray[4] = len >> 8 & 0xff;
                aDataArray[5] = len & 0xff;
                aDataArray[6] = port >> 16 & 0xff;
                aDataArray[7] = port >> 8 & 0xff;
                aDataArray[8] = port & 0xff;
                aDataArray[9] = lpServerArray.length >> 16 & 0xff;
                aDataArray[10] = lpServerArray.length >> 8 & 0xff;
                aDataArray[11] = lpServerArray.length & 0xff;
                aDataArray[12] = lpAddrArray.length >> 16 & 0xff;
                aDataArray[13] = lpAddrArray.length >> 8 & 0xff;
                aDataArray[14] = lpAddrArray.length & 0xff;
                aDataArray[15] = imgpathArray.length >> 16 & 0xff;
                aDataArray[16] = imgpathArray.length >> 8 & 0xff;
                aDataArray[17] = imgpathArray.length & 0xff;
                aDataArray[18] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[19] = packageCount & 0xff;   //包总数
                aDataArray[20] = 90;   //分包长度
                aDataArray[21] = pindex >> 8 & 0xff;   //包序号
                aDataArray[22] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < 90; i++) {
                    aDataArray[23 + i] = pathDataArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            else {
                let totalLen = 90 + tmpLen + 23;  // 此时tmpLen为负数，做加法运算
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0x92;
                aDataArray[3] = len >> 16 & 0xff;
                aDataArray[4] = len >> 8 & 0xff;
                aDataArray[5] = len & 0xff;
                aDataArray[6] = port >> 16 & 0xff;
                aDataArray[7] = port >> 8 & 0xff;
                aDataArray[8] = port & 0xff;
                aDataArray[9] = lpServerArray.length >> 16 & 0xff;
                aDataArray[10] = lpServerArray.length >> 8 & 0xff;
                aDataArray[11] = lpServerArray.length & 0xff;
                aDataArray[12] = lpAddrArray.length >> 16 & 0xff;
                aDataArray[13] = lpAddrArray.length >> 8 & 0xff;
                aDataArray[14] = lpAddrArray.length & 0xff;
                aDataArray[15] = imgpathArray.length >> 16 & 0xff;
                aDataArray[16] = imgpathArray.length >> 8 & 0xff;
                aDataArray[17] = imgpathArray.length & 0xff;
                aDataArray[18] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[19] = packageCount & 0xff;   //包总数
                aDataArray[20] = 90 + tmpLen;   //分包长度
                aDataArray[21] = pindex >> 8 & 0xff;   //包序号
                aDataArray[22] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < (90 + tmpLen); i++) {
                    aDataArray[23 + i] = pathDataArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            pindex++;
            toSleep(80);
        }

    }

}


function AddOcrRecogFile(filePath) {
    if (isSocketConnect) {

        if (filePath == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen = 11;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0xC3;
            aDataArray[3] = len >> 16 & 0xff;
            aDataArray[4] = len >> 8 & 0xff;
            aDataArray[5] = len & 0xff;
            aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[7] = packageCount & 0xff;   //包总数
            aDataArray[8] = 0;   //分包长度
            aDataArray[9] = pindex >> 8 & 0xff;   //包序号
            aDataArray[10] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(filePath);
            console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                    let totalLen = 90 + 11;
                    let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xC3;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                    let totalLen = 90 + tmpLen + 11;  // 此时tmpLen为负数，做加法运算
                    let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0xC3;
                    aDataArray[3] = len >> 16 & 0xff;
                    aDataArray[4] = len >> 8 & 0xff;
                    aDataArray[5] = len & 0xff;
                    aDataArray[6] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[7] = packageCount & 0xff;   //包总数
                    aDataArray[8] = 90 + tmpLen;   //分包长度
                    aDataArray[9] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[10] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[11 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(200);
            }
        }

//        if (filePath == "") {
//            let len = 0;
//            let totalLen = len + 3;
//            let aDataArray = new Uint8Array(totalLen);
//            aDataArray[0] = 0x77;
//            aDataArray[1] = 0x88;
//            aDataArray[2] = 0xC3;
//            for (let i = 0; i < len; i++) {
//                aDataArray[3 + i] = pathArray[i];
//            }
//            socket.send(aDataArray.buffer);
//        }
//        else {

//            let path = encodeURI(filePath);
//            let pathArray = stringToByte(path);
//            let len = pathArray.length;
//            let totalLen = len + 3;
//            let aDataArray = new Uint8Array(totalLen);
//            aDataArray[0] = 0x77;
//            aDataArray[1] = 0x88;
//            aDataArray[2] = 0xC3;
//            for (let i = 0; i < len; i++) {
//                aDataArray[3 + i] = pathArray[i];
//            }
//            socket.send(aDataArray.buffer);

//        }
    }
}


function OcrCallProEx(languageIndex, outpath, isDelSrcFile) {

    if (isSocketConnect) {

        let iRestPath = encodeURI(outpath);
        let txtpathArray = stringToByte(iRestPath);
        let len = txtpathArray.length;

        let packageCount = 0;
        let tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;
            packageCount++;
        }

        console.log("packageCount:" + packageCount);

        let pindex = 0;
        tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;

            if (tmpLen > 0) {
                let totalLen = 90 + 13;
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0xC4;
                aDataArray[3] = languageIndex;
                aDataArray[4] = isDelSrcFile;
                aDataArray[5] = len >> 16 & 0xff;
                aDataArray[6] = len >> 8 & 0xff;
                aDataArray[7] = len & 0xff;
                aDataArray[8] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[9] = packageCount & 0xff;   //包总数
                aDataArray[10] = 90;   //分包长度
                aDataArray[11] = pindex >> 8 & 0xff;   //包序号
                aDataArray[12] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < 90; i++) {
                    aDataArray[13 + i] = txtpathArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            else {
                let totalLen = 90 + tmpLen + 13;  // 此时tmpLen为负数，做加法运算
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0xC4;
                aDataArray[3] = languageIndex;
                aDataArray[4] = isDelSrcFile;
                aDataArray[5] = len >> 16 & 0xff;
                aDataArray[6] = len >> 8 & 0xff;
                aDataArray[7] = len & 0xff;
                aDataArray[8] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[9] = packageCount & 0xff;   //包总数
                aDataArray[10] = 90 + tmpLen;   //分包长度
                aDataArray[11] = pindex >> 8 & 0xff;   //包序号
                aDataArray[12] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < (90 + tmpLen); i++) {
                    aDataArray[13 + i] = txtpathArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            pindex++;
            toSleep(80);
        }

    }

}


function OcrCallPro(languageIndex, filePath, txtpath) {

    if (isSocketConnect) {

        //console.log(url);
        let urlpath = encodeURI(filePath);
        let urlpathArray = stringToByte(urlpath);
        //console.log(urlpath);

        let iRestPath = encodeURI(txtpath);
        let txtpathArray = stringToByte(iRestPath);

        let len = urlpathArray.length + txtpathArray.length;
        let pathDataArray = new Uint8Array(len);
        for (let i = 0; i < urlpathArray.length; i++) {
            pathDataArray[i] = urlpathArray[i];
        }
        for (let i = 0; i < txtpathArray.length; i++) {
            pathDataArray[urlpathArray.length + i] = txtpathArray[i];
        }

        let packageCount = 0;
        let tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;
            packageCount++;
        }

        console.log("packageCount:" + packageCount);

        let pindex = 0;
        tmpLen = len;
        while (tmpLen > 0) {
            tmpLen = tmpLen - 90;

            if (tmpLen > 0) {
                let totalLen = 90 + 18;
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0xC2;
                aDataArray[3] = languageIndex;
                aDataArray[4] = len >> 16 & 0xff;
                aDataArray[5] = len >> 8 & 0xff;
                aDataArray[6] = len & 0xff;
                aDataArray[7] = urlpathArray.length >> 16 & 0xff;
                aDataArray[8] = urlpathArray.length >> 8 & 0xff;
                aDataArray[9] = urlpathArray.length & 0xff;
                aDataArray[10] = txtpathArray.length >> 16 & 0xff;
                aDataArray[11] = txtpathArray.length >> 8 & 0xff;
                aDataArray[12] = txtpathArray.length & 0xff;
                aDataArray[13] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[14] = packageCount & 0xff;   //包总数
                aDataArray[15] = 90;   //分包长度
                aDataArray[16] = pindex >> 8 & 0xff;   //包序号
                aDataArray[17] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < 90; i++) {
                    aDataArray[18 + i] = pathDataArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            else {
                let totalLen = 90 + tmpLen + 18;  // 此时tmpLen为负数，做加法运算
                let aDataArray = new Uint8Array(totalLen);
                aDataArray[0] = 0x77;
                aDataArray[1] = 0x88;
                aDataArray[2] = 0xC2;
                aDataArray[3] = languageIndex;
                aDataArray[4] = len >> 16 & 0xff;
                aDataArray[5] = len >> 8 & 0xff;
                aDataArray[6] = len & 0xff;
                aDataArray[7] = urlpathArray.length >> 16 & 0xff;
                aDataArray[8] = urlpathArray.length >> 8 & 0xff;
                aDataArray[9] = urlpathArray.length & 0xff;
                aDataArray[10] = txtpathArray.length >> 16 & 0xff;
                aDataArray[11] = txtpathArray.length >> 8 & 0xff;
                aDataArray[12] = txtpathArray.length & 0xff;
                aDataArray[13] = packageCount >> 8 & 0xff;   //包总数
                aDataArray[14] = packageCount & 0xff;   //包总数
                aDataArray[15] = 90 + tmpLen;   //分包长度
                aDataArray[16] = pindex >> 8 & 0xff;   //包序号
                aDataArray[17] = pindex & 0xff;    //包序号
                console.log("pindex:" + pindex);
                for (let i = 0; i < (90 + tmpLen); i++) {
                    aDataArray[18 + i] = pathDataArray[i + pindex * 90];
                }
                socket.send(aDataArray.buffer);
            }
            pindex++;
            toSleep(80);
        }

    }

}

function ReadIdCard(cardImgPath) {
    if (isSocketConnect) {

        if (cardImgPath == "") {
            let len = 0;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x80;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);

        }
        else {
            let path = encodeURI(cardImgPath);
            var pathArray = stringToByte(path);
            let len = pathArray.length;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x80;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);
        }       
    }
}


function ReadIdCardEx(cardImgPath) {
    if (isSocketConnect) {

        if (cardImgPath == "") {
            let len = 0;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x81;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);

        }
        else {
            let path = encodeURI(cardImgPath);
            var pathArray = stringToByte(path);
            let len = pathArray.length;
            let totalLen = len + 3;
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x81;
            for (let i = 0; i < len; i++) {
                aDataArray[3 + i] = pathArray[i];
            }
            socket.send(aDataArray.buffer);
        }
    }
}


function GetDevCount() {
    let aDataArray = new Uint8Array(3);
    aDataArray[0] = 0x77;
    aDataArray[1] = 0x88;
    aDataArray[2] = 0x50;  
    socket.send(aDataArray.buffer);
}

function GetResolution(iCamNo) {
    let aDataArray = new Uint8Array(4);
    aDataArray[0] = 0x77;
    aDataArray[1] = 0x88;
    aDataArray[2] = 0x51;
    aDataArray[3] = iCamNo;
    socket.send(aDataArray.buffer);
}

function OpenCamera(iCamNo, width, height) {

    pALastX = 0;
    pALastY = 0;
    pACurrentX = 0;
    pACurrentY = 0;

    if (isSocketConnect) {
        let aDataArray = new Uint8Array(12);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x01;   //打开摄像头
        aDataArray[3] = iCamNo;
        aDataArray[4] = MainCanvas.width >> 8 & 0xff;
        aDataArray[5] = MainCanvas.width & 0xff;
        aDataArray[6] = MainCanvas.height >> 8 & 0xff;
        aDataArray[7] = MainCanvas.height & 0xff;
        aDataArray[8] = width >> 8 & 0xff;
        aDataArray[9] = width & 0xff;
        aDataArray[10] = height >> 8 & 0xff;
        aDataArray[11] = height & 0xff;

        socket.send(aDataArray.buffer);
    }
}

function CloseCamera() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x02;  //关闭摄像头
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}


function CaptureImage(fileAddr) {

    if (isSocketConnect) {

        // let pathArray = stringToUint8Array(fileAddr);
        if (fileAddr == "") {
            let packageCount = 1;
            let len = 0;
            let pindex = 0;
            let totalLen =  12;  
            let aDataArray = new Uint8Array(totalLen);
            aDataArray[0] = 0x77;
            aDataArray[1] = 0x88;
            aDataArray[2] = 0x10;
            aDataArray[3] = 0x00;
            aDataArray[4] = len >> 16 & 0xff;
            aDataArray[5] = len >> 8 & 0xff;
            aDataArray[6] = len & 0xff;
            aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
            aDataArray[8] = packageCount & 0xff;   //包总数
            aDataArray[9] = 0;   //分包长度
            aDataArray[10] = pindex >> 8 & 0xff;   //包序号
            aDataArray[11] = pindex & 0xff;    //包序号
            console.log("pindex:" + pindex);
            socket.send(aDataArray.buffer);
        }
        else {
            let path = encodeURI(fileAddr);
            //console.log(path);
            let pathArray = stringToByte(path);
            let len = pathArray.length;

            let packageCount = 0;
            let tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;
                packageCount++;
            }

            console.log("packageCount:" + packageCount);

            let pindex = 0;
            tmpLen = len;
            while (tmpLen > 0) {
                tmpLen = tmpLen - 90;

                if (tmpLen > 0) {
                    let totalLen = 90 + 12;
                    let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x10;
                    aDataArray[3] = 0x00;
                    aDataArray[4] = len >> 16 & 0xff;
                    aDataArray[5] = len >> 8 & 0xff;
                    aDataArray[6] = len & 0xff;
                    aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[8] = packageCount & 0xff;   //包总数
                    aDataArray[9] = 90;   //分包长度
                    aDataArray[10] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[11] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < 90; i++) {
                        aDataArray[12 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                else {
                    let totalLen = 90 + tmpLen + 12;  // 此时tmpLen为负数，做加法运算
                    let aDataArray = new Uint8Array(totalLen);
                    aDataArray[0] = 0x77;
                    aDataArray[1] = 0x88;
                    aDataArray[2] = 0x10;
                    aDataArray[3] = 0x00;
                    aDataArray[4] = len >> 16 & 0xff;
                    aDataArray[5] = len >> 8 & 0xff;
                    aDataArray[6] = len & 0xff;
                    aDataArray[7] = packageCount >> 8 & 0xff;   //包总数
                    aDataArray[8] = packageCount & 0xff;   //包总数
                    aDataArray[9] = 90 + tmpLen;   //分包长度
                    aDataArray[10] = pindex >> 8 & 0xff;   //包序号
                    aDataArray[11] = pindex & 0xff;    //包序号
                    console.log("pindex:" + pindex);
                    for (let i = 0; i < (90 + tmpLen); i++) {
                        aDataArray[12 + i] = pathArray[i + pindex * 90];
                    }
                    socket.send(aDataArray.buffer);
                }
                pindex++;
                toSleep(80);
            }
         }

    }
 }


function ZoomIn() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x03;  //放大
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}

function ZoomOut() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x04;  //缩小
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}

function BestSize() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x05;  //适合大小
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}

function TrueSize() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x06;  //1:1
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}


function Rotate(angle) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(5);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x78;
        aDataArray[3] = 0x00;
        if (angle > 3 || angle<0)
            angle=0;
        aDataArray[4] = angle;
        socket.send(aDataArray.buffer);
    }
 }

function RotateL() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x07;  //左旋
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}

function RotateR() {
    if (isSocketConnect ) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x08;  //右旋
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
}


function ManualFocus() {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x30;
        aDataArray[3] = 0x00; 
        socket.send(aDataArray.buffer);
    }
 }


function SetCutMode(cutMode) {
    if (isSocketConnect) {
        console.log('1111111111111')
        MainCamCutMode = cutMode; 
        let aDataArray = new Uint8Array(5);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x11;  //设置裁剪模式
        aDataArray[3] = 0x00;
        aDataArray[4] = cutMode;
        socket.send(aDataArray.buffer);     
    }
}



function SetCutRect(rectX,rectY,rectW,rectH) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(12);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x13;  //设置裁剪模式
        aDataArray[3] = 0x00;
        aDataArray[4] = rectX >> 8 & 0xff;
        aDataArray[5] = rectX & 0xff;
        aDataArray[6] = rectY >> 8 & 0xff;
        aDataArray[7] = rectY & 0xff;
        aDataArray[8] = rectW >> 8 & 0xff;
        aDataArray[9] = rectW & 0xff;
        aDataArray[10] = rectH >> 8 & 0xff;
        aDataArray[11] = rectH & 0xff;

        //console.log(aDataArray[8] + "," + aDataArray[9]);

        socket.send(aDataArray.buffer);
    }
}


function SetCustomArea(iX1, iY1, iX2, iY2) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(12);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x23;  //设置自定义裁剪区域
        aDataArray[3] = 0x00;
        aDataArray[4] = iX1 >> 8 & 0xff;
        aDataArray[5] = iX1 & 0xff;
        aDataArray[6] = iY1 >> 8 & 0xff;
        aDataArray[7] = iY1 & 0xff;
        aDataArray[8] = iX2 >> 8 & 0xff;
        aDataArray[9] = iX2 & 0xff;
        aDataArray[10] = iY2 >> 8 & 0xff;
        aDataArray[11] = iY2 & 0xff;

        socket.send(aDataArray.buffer);
    }
}



function ShowVideoProp() {
     if (isSocketConnect ) {
        let aDataArray = new Uint8Array(4);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x12;  //设置视频属性
        aDataArray[3] = 0x00;
        socket.send(aDataArray.buffer);
    }
 }


function MoveOffsetXY(px,py,xdir,ydir) {
    if (isSocketConnect) {
        let aDataArray = new Uint8Array(10);
        aDataArray[0] = 0x77;
        aDataArray[1] = 0x88;
        aDataArray[2] = 0x09;  //移动
        aDataArray[3] = 0x00;
        aDataArray[4] = px >> 8 & 0xff;
        aDataArray[5] = px & 0xff;
        aDataArray[6] = py >> 8 & 0xff;
        aDataArray[7] = py & 0xff;
        aDataArray[8] = xdir ;
        aDataArray[9] = ydir ;
        socket.send(aDataArray.buffer);
    }
}

function onMouseWheel(ev) { /*当鼠标滚轮事件发生时，执行一些操作*/
    ev = ev || window.event;
    let down = true;
    down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
    if (down) {
        ZoomOut();
    } else {
        ZoomIn();
    }
    return false;
}

function MainCanvasMouseDown(e) {
    isMouseDown = true;
    pALastX = e.pageX - MainCanvas.offsetLeft;
    pALastY = e.pageY - MainCanvas.offsetTop;
    if (MainCamCutMode == 2) {
        pACurrentX = pALastX;
        pACurrentY = pALastY;
    }
}

function MainCanvasMouseUp() {
    isMouseDown = false;
}

function MainCanvasMouseOut() {
    isMouseDown = false;
}

function MainCanvasMouseMove(e) {
    if (isMouseDown) {

        if (MainCamCutMode == 2) {

            pACurrentX = e.pageX - MainCanvas.offsetLeft;
            pACurrentY = e.pageY - MainCanvas.offsetTop;

            //console.log("MainCanvas:" + MainCanvas.offsetLeft + "," + MainCanvas.offsetTop);

            let rectx,recty,rectw,recth ;
            if (pALastX > pACurrentX)
                rectx = pACurrentX;
            else
                rectx = pALastX;
            if (pALastY > pACurrentY)
                recty = pACurrentY;
            else
                recty = pALastY;
            rectw = Math.abs(pACurrentX - pALastX);
            recth = Math.abs(pACurrentY - pALastY);

            SetCutRect(rectx, recty, rectw, recth);  //手动裁剪区域
            //console.log(rectx + "," + recty + "," + rectw + "," + recth);
        }
        else {
            pACurrentX = e.pageX - MainCanvas.offsetLeft;
            pACurrentY = e.pageY - MainCanvas.offsetTop;
            let dx = pACurrentX - pALastX;
            let dy = pACurrentY - pALastY;
            let xdir = 0;
            let ydir = 0;
            if (dx < 0)
                xdir = 0;
            else
                xdir = 1;
            if (dy < 0)
                ydir = 0;
            else
                ydir = 1;
            pALastX = pACurrentX;
            pALastY = pACurrentY;
            MoveOffsetXY(Math.abs(dx), Math.abs(dy), xdir, ydir);            
         }      
     }
}

/************************************************************************************************************************************************/


//>>>>>>>>>>>>>>>>.修改的地方>>>>>>>>>>>>>>>
let heartTimerId = -1;

//心跳检测
function heartCheck() {

    clearInterval(heartTimerId);
    heartTimerId = setInterval(function () {
        if (isSocketConnect) {
            let aDataArray = new Uint8Array(3);
            aDataArray[0] = 0x11;
            aDataArray[1] = 0x11;
            aDataArray[2] = 0x11;
            socket.send(aDataArray.buffer);
            console.log("heartCheck...........");
        }
    }, 6000);
}

//掉线重连
let intervalId = -1;
function reconnect(funcObj) {
        if (isManualCloseSocket) {
            clearInterval(intervalId);
            return 
        }
        clearInterval(intervalId);
        intervalId = setInterval(function () {
            if (isSocketConnect == false) {
                WebSocketConnect(funcObj);
                console.log("reconnect...")
            }
            console.log("reconnectTimer.........!")
        }, 3000);
    }

export function clearTimer () {
    heartTimerId && clearInterval(heartTimerId)
    intervalId && clearInterval(intervalId)
}


export function WebSocketConnect(getResultFuncObj) {
    socket = new WebSocket("ws://127.0.0.1:22225");
    socket.binaryType = "arraybuffer";
    try {

        socket.onopen = function () {
            heartCheck();
            isSocketConnect = true;
            clearInterval(intervalId);
            Cam_GetDevCount();
            console.log("socket.onopen");
        };
        socket.onclose = function () {
            console.log("socket.onclose");
            isSocketConnect = false;
            clearTimer()
            reconnect(getResultFuncObj);                           
            // $("TextInfo").value = "websocket connect close!"
        };

        socket.onerror = function () {
            isSocketConnect = false;
            reconnect(getResultFuncObj);
            clearTimer()
            getResultFuncObj.socketErrFunc()           
            // $("TextInfo").value = "websocket connect error!"
        };

        socket.onmessage = function (event) {

            //heartCheck.reset().start();      
            let rDataArr = new Uint8Array(event.data);
            if (rDataArr.length > 0) {


                if (rDataArr[0] == 0x11 && rDataArr[1] == 0x11 && rDataArr[2] == 0x11) {
                    console.log("heart socket!!!!");
                }

                if (rDataArr[0] == 0x55 && rDataArr[1] == 0x66) {

                    //摄像头数目返回
                    if (rDataArr[2] == 0x50) {

                        let devCount = rDataArr[3];
                        let devNameBufLen = rDataArr.length - 4;
                        let devNameData = new Uint8Array(devNameBufLen);
                        for (let i = 0; i < devNameBufLen; i++) {
                            devNameData[i] = rDataArr[4 + i];
                        }
                        //let AllCamName = Uint8ArrayToString(devNameData);
                        let str = byteToString(devNameData);
                        let AllCamName = decodeURIComponent(str);
                        let camName = new Array();
                        camName = AllCamName.split('|');
                        GetDevCountAndNameResultCB(devCount, camName);
                        getResultFuncObj.noDeviceMessage?.(devCount)
                        console.log('摄像头数目返回')
                    }

                    //摄像头分辨率返回
                    if (rDataArr[2] == 0x51) {

                        let resCount = rDataArr[3];
                        let resArr = new Array();
                        for (let i = 0; i < resCount; i++) {
                            let width = rDataArr[4 + i * 4 + 0] * 256 + rDataArr[4 + i * 4 + 1];
                            let height = rDataArr[4 + i * 4 + 2] * 256 + rDataArr[4 + i * 4 + 3];
                            let resStr = "" + width + "*" + height;
                            resArr.push(resStr);
                        }
                        GetResolutionResultCB(resCount, resArr);
                        console.log('摄像头分辨率返回')
                    }

                    //摄像头开启状态返回
                    if (rDataArr[2] == 0x01) {

                        if (rDataArr[3] == 0x01) {
                            GetCameraOnOffStatus(0);
                            //Cam_Rotate(1);
                        }
                        if (rDataArr[3] == 0x03) {
                            GetCameraOnOffStatus(1);
                        }
                        getResultFuncObj.cameraOpenSuccess?.()
                        console.log('摄像头开启状态返回')
                    }


                    if (rDataArr[2] == 0xA5) {
                        let flag;
                        if (rDataArr[3] == 0x00) {
                            flag = 2;
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (flag == 0) {
                            let ResultStr = "";
                            let pDataLen = rDataArr.length - 4;
                            if (pDataLen > 0) {
                                let pData = new Uint8Array(pDataLen);
                                for (let i = 0; i < pDataLen; i++) {
                                    pData[i] = rDataArr[4 + i];
                                }
                                let str = byteToString(pData);
                                console.log(str);
                                ResultStr = decodeURIComponent(str);
                            }
                            console.log(ResultStr);
                        }
                    }


                    //当前拍照状态返回
                    if (rDataArr[2] == 0x10) {
                        console.log('当前拍照状态返回')
                        //console.log(rDataArr[3]);
                        //GetCaptrueStatusResultCB(rDataArr[3]);
                    }



                    //拍照结果返回
                    if (rDataArr[2] == 0x10) {
                        console.log(rDataArr, 'rDataArr')
                        let flag;
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                            let imgpathLen = rDataArr[4] * 256 + rDataArr[5];
                            if (imgpathLen == 0) {
                                let base64Len = rDataArr[6] * 65536 + rDataArr[7] * 256 + rDataArr[8];
                                let imgPathStr = "";
                                let base64Data = new Uint8Array(base64Len);
                                for (let i = 0; i < base64Len; i++) {
                                    base64Data[i] = rDataArr[9 + imgpathLen + i];
                                }
                                let base64Str = Uint8ArrayToString(base64Data);
                                // GetCaptrueImgResultCB(flag, imgPathStr, base64Str);
                                getResultFuncObj.getImgBase64(flag, imgPathStr, base64Str)
                            }
                            else {
                                let base64Len = rDataArr[6] * 65536 + rDataArr[7] * 256 + rDataArr[8];
                                let pData = new Uint8Array(imgpathLen);
                                for (let i = 0; i < imgpathLen; i++) {
                                    pData[i] = rDataArr[9 + i];
                                }
                                let str = byteToString(pData);
                                let imgPathStr = decodeURIComponent(str);

                                let base64Data = new Uint8Array(base64Len);
                                for (let i = 0; i < base64Len; i++) {
                                    base64Data[i] = rDataArr[9 + imgpathLen + i];
                                }
                                let base64Str = Uint8ArrayToString(base64Data);

                                // GetCaptrueImgResultCB(flag, imgPathStr, base64Str);
                                getResultFuncObj.getImgBase64(flag, imgPathStr, base64Str)
                            }
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                            GetCaptrueImgResultCB(flag, "", "");
                            getResultFuncObj.getImgBase64(flag, '', '')
                        }
                        OcrTest(imgRealPath)
                    }

                    //身份证信息返回
                    if (rDataArr[2] == 0x80) {
                        let flag;
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        let pDataLen = rDataArr.length - 4;
                        let pData = new Uint8Array(pDataLen);
                        for (let i = 0; i < pDataLen; i++) {
                            pData[i] = rDataArr[4 + i];
                        }
                        let str = byteToString(pData);
                        let AllInfoStr = decodeURIComponent(str);
                        let resultStr = new Array();
                        resultStr = AllInfoStr.split(';');
                        //console.log(resultStr);
                        GetIdCardInfoResultCB(flag, resultStr[0], resultStr[1], resultStr[2], resultStr[3], resultStr[4], resultStr[5], resultStr[6], resultStr[7], resultStr[8], resultStr[9]);
                    }


                    //身份证信息及证件合并照片信息返回
                    if (rDataArr[2] == 0x81) {
                        let flag;
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }

                        let pDataLen = rDataArr.length - 4;
                        let pData = new Uint8Array(pDataLen);
                        for (let i = 0; i < pDataLen; i++) {
                            pData[i] = rDataArr[4 + i];
                        }
                        let str = byteToString(pData);
                        let AllInfoStr = decodeURIComponent(str);
                        let resultStr = new Array();
                        resultStr = AllInfoStr.split(';');
                        GetIdCardInfoAndImgResultCB(flag, resultStr[0], resultStr[1], resultStr[2], resultStr[3], resultStr[4], resultStr[5], resultStr[6], resultStr[7], resultStr[8], resultStr[9], resultStr[10]);
                    }

                    //上传结果返回
                    if (rDataArr[2] == 0x90) {
                        let flag;
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        if (rDataArr[3] == 0x03) {
                            flag = 3;
                        }
                        let pDataLen = rDataArr.length - 6;
                        if (pDataLen <= 0) {
                            HttpResultCB(flag, ResultStr);
                        }
                        let pData = new Uint8Array(pDataLen);
                        for (let i = 0; i < pDataLen; i++) {
                            pData[i] = rDataArr[6 + i];
                        }
                        let str = byteToString(pData);
                        console.log(str);
                        let ResultStr = decodeURIComponent(str);
                        console.log(ResultStr);
                        HttpResultCB(flag, ResultStr);

                    }


                    //添加需要OCR识别的图片文件返回结果
                    if (rDataArr[2] == 0xC3) {
                        let flag;
                        let base64Len = rDataArr.length - 4;
                        let base64Str = "";
                        if (base64Len > 0) {
                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[4 + i];
                            }
                            base64Str = Uint8ArrayToString(base64Data);
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        AddOcrRecogFileResultCB(flag, base64Str);
                    }


                    //OCR识别结果
                    if (rDataArr[2] == 0xC2) {
                        console.log(rDataArr, 'ocr')
                        let flag, proValue;
                        flag = rDataArr[3];
                        proValue = rDataArr[4];
                        let ResultStr = "";

                        console.log("result:" + flag + "_proValue:" + proValue);

                        if (flag == 0) {
                            let pathLen = rDataArr[5] * 256 + rDataArr[6];
                            let base64Len = rDataArr[7] * 65536 + rDataArr[8] * 256 + rDataArr[9];
                            let pData = new Uint8Array(pathLen);
                            for (let i = 0; i < pathLen; i++) {
                                pData[i] = rDataArr[10 + i];
                            }
                            let str = byteToString(pData);
                            ResultStr = decodeURIComponent(str);

                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[10 + pathLen + i];
                            }
                            let base64Str = Uint8ArrayToString(base64Data);

                            // OcrCallResultCB(flag, proValue, ResultStr, base64Str);
                            getResultFuncObj.getOcrResult(flag, proValue, ResultStr, base64Str)
                        }
                        else {
                            // OcrCallResultCB(flag, proValue, ResultStr, "");
                            getResultFuncObj.getOcrResult(flag, proValue, ResultStr, '')
                        }

                    }


                    //条码二维码识别结果返回
                    if (rDataArr[2] == 0x91) {
                        let flag;
                        if (rDataArr[3] == 0x00) {
                            flag = 2;    //未识别到内容
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;    //识别到内容
                        }
                        let ResultStr = "";
                        let pDataLen = rDataArr.length - 4;
                        if (flag == 0 && pDataLen > 0) {
                            let pData = new Uint8Array(pDataLen);
                            for (let i = 0; i < pDataLen; i++) {
                                pData[i] = rDataArr[4 + i];
                            }
                            let str = byteToString(pData);
                            ResultStr = decodeURIComponent(str);
                        }
                        console.log(ResultStr);
                        QrBarCodeRecogResultCB(flag, ResultStr);

                    }


                    //添加合并PDF文件结果返回
                    if (rDataArr[2] == 0x31) {
                        let flag;
                        let base64Len = rDataArr.length - 4;
                        let base64Str = "";
                        if (base64Len > 0) {
                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[4 + i];
                            }
                            base64Str = Uint8ArrayToString(base64Data);
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        AddImgFileToPDFResultCB(flag, base64Str);
                    }


                    //合并PDF结果返回
                    if (rDataArr[2] == 0x32) {
                        let flag;
                        let base64Len = rDataArr.length - 4;
                        let base64Str = "";
                        if (base64Len > 0) {
                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[4 + i];
                            }
                            base64Str = Uint8ArrayToString(base64Data);
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        PdfCombineResultCB(flag, base64Str);
                    }



                    //添加合并图像文件结果返回
                    if (rDataArr[2] == 0xC0) {
                        let flag;
                        let base64Len = rDataArr.length - 4;
                        let base64Str = "";
                        if (base64Len > 0) {
                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[4 + i];
                            }
                            base64Str = Uint8ArrayToString(base64Data);
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        AddMergeImageFileResultCB(flag, base64Str);
                    }

                    //合并图像结果返回
                    if (rDataArr[2] == 0xC1) {
                        let flag;
                        let base64Len = rDataArr.length - 4;
                        let base64Str = "";
                        if (base64Len > 0) {
                            let base64Data = new Uint8Array(base64Len);
                            for (let i = 0; i < base64Len; i++) {
                                base64Data[i] = rDataArr[4 + i];
                            }
                            base64Str = Uint8ArrayToString(base64Data);
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (rDataArr[3] == 0x02) {
                            flag = 2;
                        }
                        console.log("MergeImagesResultCB 01");
                        MergeImagesResultCB(flag, base64Str);
                        console.log("MergeImagesResultCB 02");
                    }


                    if (rDataArr[2] == 0xA5) {
                        let flag;
                        if (rDataArr[3] == 0x00) {
                            flag = 2;
                        }
                        if (rDataArr[3] == 0x01) {
                            flag = 0;
                        }
                        if (flag == 0) {
                            let pDataLen = rDataArr.length - 4;
                            if (pDataLen > 0) {
                                let pData = new Uint8Array(pDataLen);
                                for (let i = 0; i < pDataLen; i++) {
                                    pData[i] = rDataArr[4 + i];
                                }
                            }
                        }
                    }


                    //获取驱动盘符
                    if (rDataArr[2] == 0xA6) {
                        let strLen = rDataArr.length - 3;
                        if (strLen > 0) {
                            let driveData = new Uint8Array(strLen);
                            for (let i = 0; i < strLen; i++) {
                                driveData[i] = rDataArr[3 + i];
                            }
                            let driveStr = Uint8ArrayToString(driveData);
                            GetDriveResultCB(driveStr);
                        }
                        else {
                            GetDriveResultCB("");
                        }
                    }


                    //连拍返回结果
                    if (rDataArr[2] == 0xE0) {
                        if (rDataArr[3] == 0) {
                            //console.log("wise captrue fail !!");
                        }
                        else {
                            let Steady = rDataArr[4];
                            if (Steady == 1) {

                                let imgpathLen = rDataArr[5] * 256 + rDataArr[6];
                                let base64Len = rDataArr[7] * 65536 + rDataArr[8] * 256 + rDataArr[9];
                                let pData = new Uint8Array(imgpathLen);
                                for (let i = 0; i < imgpathLen; i++) {
                                    pData[i] = rDataArr[10 + i];
                                }

                                let base64Data = new Uint8Array(base64Len);
                                for (let i = 0; i < base64Len; i++) {
                                    base64Data[i] = rDataArr[10 + imgpathLen + i];
                                }
                            }
                        }

                    }


                    //删除文件结果
                    if (rDataArr[2] == 0xA8) {
                        let flag;
                        if (rDataArr[3] == 0) {
                            flag = 0;
                        }
                        else {
                            flag = 2;
                        }
                        GetDeleteFileResultCB(flag);
                    }


                    //摄像头数据
                    if (rDataArr[2] == 0xcc) {

                        let ww = rDataArr[3] * 256 + rDataArr[4];
                        let hh = rDataArr[5] * 256 + rDataArr[6];
                        pMainShowStartX = rDataArr[7] * 256 + rDataArr[8];
                        pMainShowStartY = rDataArr[9] * 256 + rDataArr[10];
                        MainContext.clearRect(0, 0, MainCanvas.width, MainCanvas.height);
                        let imgData = MainContext.createImageData(ww, hh);
                        let dataNum = 0;
                        dataNum = dataNum + 11;
                        for (let i = 0; i < imgData.data.length; i += 4) {
                            imgData.data[i + 0] = rDataArr[dataNum];
                            imgData.data[i + 1] = rDataArr[dataNum + 1];
                            imgData.data[i + 2] = rDataArr[dataNum + 2];
                            imgData.data[i + 3] = 255;
                            dataNum = dataNum + 3;
                        }

                        MainContext.putImageData(imgData, pMainShowStartX, pMainShowStartY);

                        if (MainCamCutMode == 2) {
                            MainContext.strokeStyle = 'blue'; // 设置线条的颜色
                            MainContext.lineWidth = 2; // 设置线条的宽度
                            MainContext.beginPath(); // 绘制直线
                            MainContext.rect(pALastX, pALastY, (pACurrentX - pALastX), (pACurrentY - pALastY));
                            MainContext.closePath();
                            MainContext.stroke();
                        }
                    }

                    rDataArr = null;

                }
            }



        };    
    }
    catch (ex) {
        alert("异常错误!") 
    }
}

//时间格式化显示
function formatDate(time) {
  let date = new Date(time);
  let year = date.getFullYear(),
      month = date.getMonth() + 1, 
      day = date.getDate(),
      hour = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();
  let newTime = year + 
              (month < 10 ? '0' + month : month) + 
              (day < 10 ? '0' + day : day) + 
              (hour < 10 ? '0' + hour : hour) + 
              (min < 10 ? '0' + min : min) + 
              (sec < 10 ? '0' + sec : sec) ;
  return newTime;
  }
  
  
  function sleep(milliSeconds) {
  let startTime = new Date().getTime();
  while (new Date().getTime() < startTime + milliSeconds);
  }
  
  function ShowInfo(op) {
  let obj = document.getElementById("TextInfo");
  obj.value = obj.value + "\r\n" + op
  }
  
  
  /*----------------------------------------------------
  ---（必须重写该函数）返回获取的设备数目及设备名称  ---
  -----------------------------------------------------*/
  function GetDevCountAndNameResultCB(devCount, devNameArr) {
  
  if (devCount > 0) {
  
      let obj = document.getElementById("DevName");
      if (!obj) return
      obj.options.length = 0;
      for (let i = 0; i < devCount; i++) {
          let objOption = document.createElement("option");
          objOption.text = devNameArr[i];
          objOption.value = i;
          obj.options.add(objOption);
      }
      obj.selectedIndex = 0;
      let CamID = obj.selectedIndex;
  
      //获取分辨率
      Cam_GetDevResolution(CamID);  
      
  }
  else {
      ShowInfo("没有发现合适的设备！");
    }
  }
  
  /*---------------------------------------------------
  ---  （必须重写该函数）返回获取的设备分辨率信息   ---
  ----------------------------------------------------*/
  function GetResolutionResultCB(resCount, resArr) {
  
  if (resCount > 0) {
      let selectIndex = 0;
      let obj = document.getElementById("DevResolution");
      obj.options.length = 0;
      for (let i = 0; i < resCount; i++) {
          let objOption = document.createElement("option");
          objOption.text = resArr[i];
          objOption.value = i;
          obj.options.add(objOption);
          //默认500万分辨率打开
          if (resArr[i] == "2592*1944") {
              selectIndex = i;
            }
      }
      obj.selectedIndex = selectIndex;
  
      //打开摄像头
      Cam_Close();
      sleep(100);
      let restr = obj[obj.selectedIndex].text;
      let pos = restr.lastIndexOf("*");
      let width = parseInt(restr.substring(0, pos));
      let height = parseInt(restr.substring(pos + 1, restr.length));
      let CamID = document.getElementById("DevName").selectedIndex;
      Cam_Open(CamID, width, height);
  }
  else {
      ShowInfo("获取分辨率信息失败！");
    }
  }
  
  
  /*---------------------------------------------------
  ---     （必须重写该函数）返回摄像头开启状态      ---
  ----------------------------------------------------*/
  function GetCameraOnOffStatus(status) {
  if (status == 0) {
      ShowInfo("设备开启成功");
      //Cam_Rotate(1);   // 0-> 0度, 1->90度, 2->180度, 3->270度
  }
  else {
      ShowInfo("设备开启失败!");
    }
  
  }
  
  
  /*---------------------------------------------------
  --------     （必须重写该函数）拍照结果     ---------
  ----------------------------------------------------*/
  function GetCaptrueImgResultCB( flag, path , base64Str) {
  if (flag == 0) {
      let obj = document.getElementById("CameraPhoto");
      obj.src = "data:;base64," + base64Str;
      if (path == "") {
          ShowInfo("拍照成功");
      }
      else {
          ShowInfo("拍照成功，图片保存位置：" + path);
        }
  }
  else {
      ShowInfo("拍照失败!");
  }
  
  }
  
  
  /*---------------------------------------------------
  ------  （必须重写该函数）身份证信息返回结果   ------
  ----------------------------------------------------*/
  function GetIdCardInfoResultCB(flag, Name, Sex, Nation, Born, Address, CardNum, IssuedAt, EffectedDate, CardImgPath, CardImgBase64) {
  if (flag == 0) {
      document.getElementById("CardName").value = Name;
      document.getElementById("CardSex").value = Sex;
      document.getElementById("CardNation").value = Nation;
      document.getElementById("CardBorn").value = Born;
      document.getElementById("CardAddress").value = Address;
      document.getElementById("CardNum").value = CardNum;
      document.getElementById("CardIssuedAt").value = IssuedAt;
      document.getElementById("CardEffectDate").value = EffectedDate;
      let obj = document.getElementById("IdCardPhoto");
      obj.src = "data:;base64," + CardImgBase64;
      ShowInfo("读卡成功");
  }
  else {
      ShowInfo("读卡失败!");
  }
  
  }
  
  
  
  /*-------------------------------------------------------------------
  ------  （必须重写该函数）身份证信息及正反面合并照片返回结果   ------
  --------------------------------------------------------------------*/
  function GetIdCardInfoAndImgResultCB(flag, Name, Sex, Nation, Born, Address, CardNum, IssuedAt, EffectedDate, CardImgPath, CardHeadImgBase64, CardImgBase64) {
  if (flag == 0) {
      document.getElementById("CardName").value = Name;
      document.getElementById("CardSex").value = Sex;
      document.getElementById("CardNation").value = Nation;
      document.getElementById("CardBorn").value = Born;
      document.getElementById("CardAddress").value = Address;
      document.getElementById("CardNum").value = CardNum;
      document.getElementById("CardIssuedAt").value = IssuedAt;
      document.getElementById("CardEffectDate").value = EffectedDate;
      let obj = document.getElementById("IdCardPhoto");
      obj.src = "data:;base64," + CardHeadImgBase64;
      let obj1 = document.getElementById("CameraPhoto");
      obj1.src = "data:;base64," + CardImgBase64;
      ShowInfo("读卡成功");
  }
  else {
      ShowInfo("读卡失败!");
  }
  
  }
  
  
  /*---------------------------------------------------
  ------  （必须重写该函数）条码二维码识别返回结果------
  ----------------------------------------------------*/
  function QrBarCodeRecogResultCB(flag,codeStr) {
  if (flag == 0)
      ShowInfo("条码/二维码 识别结果:" + codeStr);
  else
      ShowInfo("未识别到内容!");
  }
  
  
  
/*********************
***    初始化操作  ***
**********************/
export function LoadCameraDocument(getResultFuncObj) {
  if (!window.WebSocket) {
      alert("浏览器不支持HTML5,请更新浏览器或者使用其它浏览器");
  }
  let obj = document.getElementById("CameraCtl");
  Cam_ControlInit(obj,0, 0, 726, 539, getResultFuncObj);
}
  
/*********************
***    打开摄像头  ***
**********************/
export function toOpenCamera() {
  let CamID = document.getElementById("DevName").selectedIndex;
  let obj = document.getElementById("DevResolution");
  let restr = obj[obj.selectedIndex].text;
  let pos = restr.lastIndexOf("*");
  let width = parseInt(restr.substring(0, pos));
  let height = parseInt(restr.substring(pos + 1, restr.length));
  Cam_Open(CamID, width, height);

}
  
/*********************
***    关闭摄像头  ***
**********************/
export function toCloseCamera() {
  Cam_Close();
}
  
  
/*********************
***    切换摄像头  ***
**********************/
export function SelectDevice() {
  let CamID = document.getElementById("DevName").selectedIndex;
  //获取分辨率
  Cam_GetDevResolution(CamID);  
}
  
  
/*********************
 ***    切换分辨率  ***
**********************/
export function SelectResolution() {
  let obj = document.getElementById("DevResolution");
  let restr = obj[obj.selectedIndex].text;
  let pos = restr.lastIndexOf("*");
  let width = parseInt(restr.substring(0, pos));
  let height = parseInt(restr.substring(pos + 1, restr.length));
  let CamID = document.getElementById("DevName").selectedIndex;
  Cam_Open(CamID, width, height); 
}
  
  
/*********************
 ***       拍照     ***
**********************/
export function TakePhoto() {
  let name = formatDate(new Date().getTime());
  let obj = document.getElementById("FileType");
  let path;
  Cam_SetFileType(obj.selectedIndex); //设置文件格式
  if (obj.selectedIndex == 0) {
      path = "C:\\Users\\" + name + ".jpg";
    }
  else if (obj.selectedIndex == 1) {
      path = "C:\\Users\\" + name + ".png";
  }
  else if (obj.selectedIndex == 2) {
      path = "C:\\Users\\" + name + ".tif";
  }
  else if (obj.selectedIndex == 3) {
      path = "C:\\Users\\" + name + ".pdf";
  }
  else {
      path = "C:\\Users\\" + name + ".jpg";
  }
  imgRealPath = path
  Cam_Photo(path);  //主摄像头拍照
}
  
  
export function SetCameraCutMode() {
  if (document.getElementById("Radio1").checked) {
      Cam_SetCutMode(0);
  }
  if (document.getElementById("Radio3").checked) {
      Cam_SetCutMode(1);
  }
  if (document.getElementById("Radio2").checked) {
      Cam_SetCutMode(2);
  }
  if (document.getElementById("Radio4").checked) {
      Cam_SetCutMode(3);
      SetCustomArea(3000,3000,9000,9000);
  } 
}
  
  
/*********************
***    读取身份证  ***
**********************/
export function GetIdCardInfo() {
  let path = "D:\\IdCard.jpg";
  Cam_ReadIdCardEx(path);
}
  
  
/*********************
***    设置文件格式  ***
**********************/
export function toSetFileType() {
  let obj = document.getElementById("FileType");
  Cam_SetFileType(obj.selectedIndex);
}
  
  
/*********************
***    设置色彩模式  ***
**********************/
export function toSetColorModel() {
  let obj = document.getElementById("ColorMode");
  Cam_SetColorMode(obj.selectedIndex);
}
  
  
/*********************
***    设置JPG图像质量  ***
**********************/
export function toSetJpgQuality() {
  let obj = document.getElementById("JpgQuality");
  let val = obj[obj.selectedIndex].text;
  Cam_SetJpgQuality(val);
}
  
  
/*********************
 ***    设置去黑边  ***
**********************/
export function toSetDeleteBlackEdge() {
  let obj = document.getElementById("Checkbox1");
  if (obj.checked) {
      Cam_SetDeleteBlackEdge(1);
  }
  else {
      Cam_SetDeleteBlackEdge(0);
  }
}
  
  /*********************
   ***    设置去底色  ***
  **********************/
  export function toSetDeleteBgColor() {
    let obj = document.getElementById("Checkbox2");
    if (obj.checked) {
        Cam_SetDeleteBgColor(1);
    }
    else {
        Cam_SetDeleteBgColor(0);
    }
  }
  
  
  /*---------------------------------------------------
  --------     （必须重写该函数）上传结果     ---------
  ----------------------------------------------------*/
  export function HttpResultCB(flag, ResultStr) {
  if (flag == 0) {
      alert("上传成功 :" + ResultStr);
  }
  else {
      alert("上传失败！");
  }
  }
  
  
  /*********************
  ******    上传  ******
  **********************/
  export function HttpUploadFile() {
  
  let filePath = "D:\\test.jpg";
  let url = "http://localhost:8980/js/HaPXZX/training/trainAttendance/upLoadFile?classId=1622415225972805632";
  UploadFile(url, filePath);
  
  }
  
  
  //从摄像头中识别二维码
  export function RecogQrCodeFromCamera(type){
  Cam_RecogQrBarCodeFromCamera(type)
  }
  
  //从图片文件中识别二维码
  export function RecogBarCodeFromFile(type) {
  let imgpath = "D:\\123.jpg";
  Cam_RecogQrBarCodeFromFile(type, imgpath)
  }
  
  //添加要合并的PDF文件
  export function ToAddPDFFile() {
  Cam_AddImgFileToPDF("");
  sleep(100);
  }
  
  /*---------------------------------------------------
  --------     添加合并的PDF文件返回结果     ---------
  ----------------------------------------------------*/
  export function AddImgFileToPDFResultCB(flag, base64Str)
  {
  if (flag == 0) {
      ShowInfo("添加合并的PDF文件成功");
      let obj = document.getElementById("CameraPhoto");
      obj.src = "data:;base64," + base64Str;
  }
  else {
      ShowInfo("添加合并的PDF文件失败!");
    }
  }
  
  
  //PDF合并测试
  export function ToCombinePDF() {
  Cam_CombinePDF("D:\\test.pdf");  
  }
  
  /*---------------------------------------------------
  --------     （必须重写该函数）合并PDF结果     ---------
  ----------------------------------------------------*/
  export function PdfCombineResultCB(flag, PdfBase64Str) {
  if (flag == 0) {
      ShowInfo("合并PDF完成");
      console.log(PdfBase64Str);
  }
  else {
      ShowInfo("合并PDF失败!");
  }
  }
  
  
  //添加要合并的图像文件
  export function ToAddMergeImageFile() {
  Cam_AddMergeImageFile("");
  sleep(100);
  }
  
  /*---------------------------------------------------
  --------     添加合并图像文件返回结果     ---------
  ----------------------------------------------------*/
  export function AddMergeImageFileResultCB(flag, base64Str) {
  if (flag == 0) {
      ShowInfo("添加合并图像文件成功");
      let obj = document.getElementById("CameraPhoto");
      obj.src = "data:;base64," + base64Str;
  }
  else {
      ShowInfo("添加合并图像文件失败!");
  }
  }
  
  
  //图像合并测试
  export function ToMergeImages() {
  Cam_MergeImages("D:\\merge.jpg",0);     
  }
  
  /*---------------------------------------------------
  --------     （必须重写该函数）图像合并结果     ---------
  ----------------------------------------------------*/
  export function MergeImagesResultCB(flag, base64Str) {
  if (flag == 0) {
      ShowInfo("图像合并完成");
      let obj = document.getElementById("CameraPhoto");
      obj.src = "data:;base64," + base64Str;
  }
  else {
      ShowInfo("图像合并失败!");
  }
  }
  
  
  
  
  /*---------------------------------------------------
  -------（必须重写该函数）获取驱动盘符返回结果--------
  ----------------------------------------------------*/
  export function GetDriveResultCB(driveStr) {
  if (driveStr == "") {
      ShowInfo("获取盘符失败!");
  }
  else {
      ShowInfo(driveStr);
    }
  }
  
  
  //设置水印
  export function toSetWaterMarkParams() {
    let isAddMark;
    let isAddTime;
    let wTransp;
    let wPos;
    let wSize;
    let wColor;
    let szInfo;
    let obj = document.getElementById("Checkbox3");
    if (obj.checked)
        isAddMark = 1;
    else
        isAddMark = 0;
    obj = document.getElementById("Checkbox4");
    if (obj.checked)
        isAddTime = 1;
    else
        isAddTime = 0;
    szInfo = document.getElementById("Text1").value;   //水印内容
    wTransp = parseInt(document.getElementById("Text2").value); //透明度
    wSize = parseInt(document.getElementById("Text3").value);   //水印大小
    wPos = document.getElementById("Select1").selectedIndex;   //水印位置
    wColor = document.getElementById("Select2").selectedIndex;   //水印颜色
    SetWaterMark(isAddMark, 0, isAddTime, wTransp, wPos, wSize, wColor, szInfo);  //生效水印设置
  }
  
  
  
  //删除文件
  export function ToDeleteFile() {
    let path = "D:\\log.txt";
    DeleteFile(path);
  }
  
  //删除文件返回结果
  export function GetDeleteFileResultCB(flag) {
      if (flag == 0) {
          ShowInfo("删除文件成功");
      }
      else {
          ShowInfo("删除文件失败！");
      }
  }
  
  
  
  /*----------------------------------------------------------------
  -------（必须重写该函数）添加需要OCR识别的图片文件返回结果--------
  --------------------------------------------------------------*/
  export function AddOcrRecogFileResultCB(flag, base64Str) {
      if (flag == 0) {
          ShowInfo("添加需要OCR识别的图片文件成功");
          let obj = document.getElementById("CameraPhoto");
          obj.src = "data:;base64," + base64Str;
      }
      else {
          ShowInfo("添加文件失败!");
      }
  }
  
  //添加文件
  export function ToAddOcrRecogFile() {
  
      //AddOcrRecogFile("");  //传空拍照添加，也可以传文件路径    
  
      let path1 = "D:\\1.jpg";
      let path2 = "D:\\2.jpg";
      let path3 = "D:\\3.jpg";
      let path4 = "D:\\4.jpg";
      let path5 = "D:\\5.jpg";
      let path6 = "D:\\6.jpg";
      //let path7 = "D:\\7.jpg";
      let allpath = path1 + ";" + path2 + ";" + path3 + ";" + path4 + ";" + path5 + ";" + path6 ;
      console.log(allpath);
      AddOcrRecogFile(allpath);
  }
  
  
  /*---------------------------------------------------
  -------（必须重写该函数）OCR识别结果返回--------
  ----------------------------------------------------*/
  export function OcrCallResultCB(flag,proValue, ResultStr, base64Str) {
      if (flag == 0) {
          ShowInfo("Ocr文字识别成功：" + ResultStr);
          //ShowInfo("base64data：" + base64Str);
          let obj = document.getElementById("mypdf");
          obj.src = "data:application/pdf;base64," + base64Str;
      }
      else if (flag == 1) {
          ShowInfo("Ocr文字识别进度：" + proValue);  
      }
      else {
          if (flag == 40)
              ShowInfo("Ocr授权失败！");
          else
              ShowInfo("Ocr文字识别失败！");
      }
  }
  
  
  //OCR识别(多张合并识别)
  export function ToOcrCallProEx() {
      OcrCallProEx(0, "D:\\out.pdf",0);
  
  }
  
  //OCR测试(单张识别)
  export function OcrTest(path) {
    OcrCallPro(0, path, "C:\\Users\\test.txt");
  }
  
  //开始录像
  export function ToStartRecord() {
      ShowInfo("开始录像");
      StartRecord("D:\\test.avi", 10);
  }
  
  //停止录像
  export function ToStopRecord() {
      ShowInfo("关闭录像，D:\\test.avi");
      StopRecord();
  }

export function getSocketInstance() {
    return { socket, heartTimerId, intervalId, }
}


export function setIsManualCloseSocket(val) {
    isManualCloseSocket = val
}
