<template>
  <div class="ocr-img-container">
 <div style="width:726px;">

<div id='CameraCtl' style="width:726px;height: 539px;">
  <div class="loading-box">
    <Loading class="loading-svg" />
    设备启动中，请稍后...
  </div>
</div>
<div class="hidden">
    设备
    <select style="width:140px" id="DevName"></select> 
    分辨率
    <select style="width:140px" id="DevResolution"></select> 
    <input type = "button" value = " 打开设备 "/>
    <input type = "button" value = " 关闭设备 "/>
    <br />
        
    裁切模式:               
    <input id="Radio1"  name="R1" type="radio" value="V1" checked="checked"/>不裁切
    <input id="Radio2"  name="R1" type="radio" value="V2"/>手动裁切
    <input id="Radio3"  name="R1" type="radio" value="V3"/>自动裁切
    <input id="Radio4"  name="R1" type="radio" value="V4"/>自定义
    <br />

    文件格式: 
    <select   id="FileType">
        <option value="0">jpg</option>
        <option value="1">png</option>
        <option value="2">tif</option>
        <option value="3">pdf</option>
    </select> 

    色彩模式: 
    <select   id="ColorMode">
        <option value="0">彩色</option>
        <option value="1">灰度</option>
        <option value="2">黑白</option>
    </select> 

    图像质量: 
    <select   id="JpgQuality">
        <option value="0">10</option>
        <option value="1">20</option>
        <option value="2">30</option>
        <option value="3">40</option>
        <option value="4">50</option>
        <option value="5" selected="true">60</option>
        <option value="6">70</option>
        <option value="7">80</option>
        <option value="8">90</option>
        <option value="9">100</option>
    </select> 
    
    <input id="Checkbox1" type="checkbox"/> 去黑边   
    <input id="Checkbox2" type="checkbox"/> 去底色

</div>

<div class="hidden">
    <input id="Checkbox3" type="checkbox" /> 添加水印              
    水印内容<input id="Text1" type="text" value="水印测试" />
    透明度<input id="Text2" type="text" value="127" style="width:40px" />
    大小<input id="Text3" type="text" value="80" style="width:40px"/>  <br />
    位置
    <select   id="Select1">
        <option value="0">左上</option>
        <option value="1">右上</option>                    
        <option value="2">左下</option>
        <option value="3">右下</option>
        <option value="4">中间</option>
    </select> 
    颜色
    <select   id="Select2" >
        <option value="0">红色</option>
        <option value="1">绿色</option>
        <option value="2">蓝色</option>
        <option value="3">青色</option>
        <option value="4">黄色</option>
        <option value="5">白色</option>
        <option value="6">黑色</option>
    </select> 
    <input id="Checkbox4" type="checkbox" /> 添加时间水印    
    <input type = "button" value = "生效水印参数设置"/>   
</div>
<div style="margin-top: 10px;text-align: center;">
  <el-button class="take-photo-btn" type="primary" :loading="inPhotography" @click="photograph" size="small">
    <el-icon class="camera-icon">
      <Camera />
    </el-icon>
    拍照
  </el-button>
</div>


<div class="hidden">
  <input type = "button" value = "OCR" @click="OcrTest();" />
    <input type = "button" value = "获取盘符" />
    <input type = "button" value = "放大" />
    <input type = "button" value = "缩小"/>
    <input type = "button" value = "适屏" />
    <input type = "button" value = "1:1" />
    <input type = "button" value = "左旋" />
    <input type = "button" value = "右旋" />
    
    <input type = "button" value = "对焦" />
    <input type = "button" value = "设置" />  
    <input type = "button" value = "上传" />     
    <input type = "button" value = "读身份证" />                
    <input type = "button" value = "从摄像头中识别二维码" />
    <input type = "button" value = "从图片中识别二维码" />
    <input type = "button" value = "添加合并PDF文件" />
    <input type = "button" value = "合并PDF" />
    <input type = "button" value = "添加合并图像文件" />
    <input type = "button" value = "合并图像" />
    <input type = "button" value = "删除文件" />
    <input type = "button" value = "开始录像" />
    <input type = "button" value = "停止录像" />
    <input type = "button" value = "添加需要OCR识别的图片文件" />
    <input type = "button" value = "OCR识别(多张合并识别)" />
</div>

<div style="width:100%;">
  <div class="save-url">
    <span>图片保存地址：</span>
    <span>{{ imgSavePath }}</span>
  </div>
  <textarea style="display: block;height: 0;" :disabled="true" class="textarea-info" id="TextInfo" cols="20" rows="0"></textarea>
</div>
      
</div>
<div class="img-result">
  <h3 class="result-title">照片拍摄结果展示</h3>
  <div class="result-box">
    <div v-for="(item, index) in imgBase64List" :key="index" class="img-item">
      <img id="CameraPhoto" :src="item" alt="拍摄结果展示" style="width: 160px;" />
      <!-- <el-image :initial-index="i" zoom-rate="1.2" :preview-src-list="imgBase64List" :min-scale="0.2" :max-scale="7" style="width: 160px;height: 120px;" /> -->
      <div class="view-icon-box">
        <el-tooltip placement="top" effect="dark" content="点击查看图片">
          <el-icon @click="() => showImagePreviewFunc(index)">
            <View />
          </el-icon>
        </el-tooltip>
        <el-tooltip placement="top" effect="dark" :content="isUploadAll ? '已上传' : '点击删除图片'">
          <el-icon :class="['close-icon', isUploadAll && 'is-upload']" :style="{fontSize: isUploadAll ? '18px' : '14px'}" @click="delCurrent(index)">
            <Close v-if="!isUploadAll" />
            <SuccessFilled v-else />
          </el-icon>
        </el-tooltip>
      </div>
    </div>
  </div>
  <div class="uplaod-btn">
    <el-select v-model="uploadType" class="w-100" placeholder="请选择类型" size="small" @change="changeType">
      <el-option v-for="item in imgUploadOptions" :key="item.value" :value="item.value" :label="item.label" />
    </el-select>
    <el-select v-if="uploadType === '1'" v-model="markType" class="w-130" placeholder="请选择票据类型" size="small">
      <el-option v-for="item in typeOptions" :key="item.value" :value="item.value" :label="item.label" />
    </el-select>
    <el-button class="take-photo-btn upload-btn" type="primary" @click="uploadImgAndOcrResult();" size="small">
      <el-icon class="camera-icon">
        <Upload />
      </el-icon>
      上传
    </el-button>
  </div>
  
</div>

<!-- style="width:202px; height: 640px; border: 1px solid white; background:#C7EDCC;  float:left" -->
<div class="hidden">
拍照图片
<br />

<img id="CameraPhoto2"  src="" style="width: 198px;height: 198px;" />
<br />
身份证信息
<br />
<img id="IdCardPhoto"  src="" style="width: 80px;height: 100px;"  />
<br />
姓名
<input id="CardName" type="text"  style="width: 100px"/> <br />
性别
<input id="CardSex" type="text"  style="width: 50px"/>
民族
<input id="CardNation" type="text"  style="width: 60px"/> <br />
出生
<input id="CardBorn" type="text"  style="width: 100px"/>  <br />
住址 
<br />
<textarea  id="CardAddress" cols="20" rows="3"  style="width:192px"></textarea>
身份证号码
<br />
<input id="CardNum" type="text"  style="width: 195px"/>  <br />
签发机关
<input id="CardIssuedAt" type="text"  style="width: 125px"/>  <br />
有效期限
<input id="CardEffectDate" type="text"  style="width: 125px"/>  <br />

<embed id="mypdf" src="" type="application/pdf" width="200" height="100"/>

</div>

  <el-dialog
    v-model="dialogVisible"
    title="提示"
    width="270px"
  >
    <span style="font-size: 13px;">
      <i class="iconfont icon-gantanhao1" style="font-size: 13px"></i>检测到您未使用科密识别设备，请联系客服人员进行购买
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" type="primary">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="downloadExeDialogVisible"
    title="提示"
    width="300px"
  >
    <span style="font-size: 13px;">
      <i class="iconfont icon-gantanhao1" style="font-size: 13px"></i>请检查是否安装科密设备驱动软件，如已下载请启动驱动程序。如未下载，请点击
    </span>
    <span style="font-size: 13px;display:inline-block;margin-top:10px">
      <el-button
        size="small"
        type="primary"
        @click="checkOpen"
        ><ElIcon><Pointer /></ElIcon>去下载</el-button>
      安装后并启动进行重试
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="downloadExeDialogVisible = false" size="small" type="primary">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <el-image-viewer
    v-if="showImagePreview"
    ref="imageViewerRef"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgBase64List"
    :initial-index="0"
  />
</div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'
import { ElMessage, ElButton, ElDialog, ElIcon, ElSelect, ElOption, ElTooltip, ElImageViewer } from 'element-plus'
import { Loading, Pointer, Camera, Upload, Close, SuccessFilled, View } from '@element-plus/icons-vue'

import { LoadCameraDocument, TakePhoto, OcrTest, Cam_SetCutMode } from './utils'
import { imgUploadOptions, typeOptions } from '../constant'

import { uploadOcrData } from '@/api/company'

const props = defineProps({
  comId: {
    type: Number,
    default: 0,
    required: true,
  },
  period: {
    type: String,
    default: ''
  }
})
const imgBase64List = ref([])
const imgUploadList = ref([])
const dialogVisible = ref(false)
const downloadExeDialogVisible = ref(false)
const isDownloadExe = ref(false)
const imgSavePath = ref('')
const uploadType = ref('2')
const markType = ref('')
const showImagePreview = ref(false)
const isUploadAll = ref(false)
const imageViewerRef = ref()
const inPhotography = ref(false)

onMounted(() => {
  LoadCameraDocument({
    getImgBase64, getOcrResult,
    cameraOpenSuccess,
    noDeviceMessage,
    socketErrFunc,
  })
})

function photograph() {
  if (isUploadAll.value) {
    isUploadAll.value = false
    imgBase64List.value = []
    imgUploadList.value = []
  }
  if (imgBase64List.value.length >= 12) {
    return ElMessage.error('每次上传最多12张图片')
  }
  inPhotography.value = true
  TakePhoto();
}

function showImagePreviewFunc (i) {
  showImagePreview.value = true
  setTimeout(() => {
    imageViewerRef.value?.setActiveItem(i)
  })
}

function delCurrent(i) {
  if (isUploadAll.value) return 
  imgBase64List.value.splice(i, 1)
  imgUploadList.value.splice(i, 1)
}

function changeType() {
  if (uploadType.value === '2') {
    markType.value = ''
  }
}

function checkOpen() {
  isDownloadExe.value = true
  window.open("https://file.listensoft.net/mnt/down/KMGpyServer_3.9.2.exe")
}

function socketErrFunc() {
  if (isDownloadExe.value) return
  if (downloadExeDialogVisible.value) return
  downloadExeDialogVisible.value = true
}

function noDeviceMessage(deviceCount) {
  setTimeout(() => {
    if (deviceCount <= 0) {
      dialogVisible.value = true
    }
  })
}

function cameraOpenSuccess() {
  document.body.querySelector('#CameraCtl .loading-box').style.display = 'none'
  Cam_SetCutMode(1);
}

function uploadImgAndOcrResult() {
  if (!imgUploadList.value.length) return ElMessage.error('请先拍照')
  if (!uploadType.value) return ElMessage.error('请选择类型')
  if (uploadType.value === '1' && !markType.value) return ElMessage.error('请选择票据类型')
  uploadOcrData({
    base64Img: imgUploadList.value,
    comId: props.comId,
    period: props.period,
    voucherType: uploadType.value,
    markType: uploadType.value === '1' ? markType.value : undefined
  })
    .then(res => {
      if (res.data.msg === 'success') {
        ElMessage.success('图片上传成功')
        isUploadAll.value = true
      }
    })
}

function getImgBase64 (flag, path , base64Str) {
  if (flag == 0) {
      // let obj = document.getElementById("CameraPhoto");
      const imgsrc = "data:;base64," + base64Str;
      imgBase64List.value.push(imgsrc)
      imgUploadList.value.push(base64Str)
      imgSavePath.value = path
      ElMessage.success("拍照成功");
  } else {
    ElMessage.error("拍照失败!");
  }
  inPhotography.value = false
}

function getOcrResult (flag,proValue, ResultStr, base64Str) {
  if (flag == 0) {
    ElMessage.success("Ocr文字识别成功：" + ResultStr);
    let obj = document.getElementById("mypdf");
    obj.src = "data:application/pdf;base64," + base64Str;
  }
  else if (flag == 1) {
    console.log("Ocr文字识别进度：" + proValue);  
  }
  else {
    if (flag == 40)
    console.log("Ocr授权失败！");
    else
    console.log("Ocr文字识别失败！");
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  position: absolute;
  height: 0;
  overflow: hidden;
}
.textarea-info {
  display: block;
  width: 100%;
  height: 0;
  box-sizing: border-box;
  opacity: 0;
}
#CameraCtl {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.loading-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .7);
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  box-sizing: border-box;
}
.loading-svg {
  width: 20px;
  height: 20px;
}
.ocr-img-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
#CameraPhoto {
  display: block;
}

.take-photo-btn {
  margin: auto;
  width: 300px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  .camera-icon {
    margin-right: 5px;
    font-size: 16px;
  }
}
.img-result {
  position: relative;
  width: 350px;
  .result-box {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 8px;
    column-gap: 10px;
    margin: 10px 0px;
    height: calc(100% - 120px);
    overflow-y: auto;
    .img-item {
      position: relative;
      cursor: pointer;
      .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        &.is-upload {
          color: var(--themeColor, #17a2b8);
        }
      }
    }
  }
}
.upload-btn {
  width: 100%;
}
:deep(.el-select) {
  margin: 0;
  width: 200px;
  margin-bottom: 10px;
}
.save-url {
  margin-top: 10px;
}
.view-icon-box {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .2);
  
  i {
    color: #fff;
  }
}
</style>