export const imgUploadOptions = [
  { label: '票据', value: '1' },
  { label: '附件', value: '2' },
  { label: '银行', value: '4' },
]

export const uploadTypeEnum = imgUploadOptions.reduce((result, item) => {
  result[item.value] = item.label
  return result
}, {})

export const typeOptions = [
  {
    label:"增值税普通发票（收入）",
    value:"增值税普通发票（收入）"
  },
  {
    label:"增值税普通发票（成本）",
    value:"增值税普通发票（成本）"
  },
  {
    label:"会费收入",
    value:"会费收入"
  },
  {
    label:"捐赠收入",
    value:"捐赠收入"
  },
  {
    label:"缴税回单",
    value:"缴税回单"
  },
  {
    label:"商品销售收入（普票）",
    value:"商品销售收入（普票）"
  },

  {
    label:"购买公积金",
    value:"购买公积金"
  },
  {
    label:"增值税电子普通发票（收入）",
    value:"增值税电子普通发票（收入）"
  },

  {
    label:"购买社保",
    value:"购买社保"
  },

  {
    label:"取现",
    value:"取现"
  },

  {
    label:"政府补助收入",
    value:"政府补助收入"
  },

  {
    label:"增值税专用发票（收入）",
    value:"增值税专用发票（收入）"
  },
  {
    label:"存现",
    value:"存现"
  },

  {
    label:"银行手续费回单",
    value:"银行手续费回单"
  },

  {
    label:"费用票据",
    value:"费用票据"
  },

  {
    label:"服务成本（专票）",
    value:"服务成本（专票）"
  },

  {
    label:"增值税专用发票（代开收入）",
    value:"增值税专用发票（代开收入）"
  },
  {
    label:"银行收款回单（公司）",
    value:"银行收款回单（公司）"
  },
  {
    label:"银行付款回单（公司）",
    value:"银行付款回单（公司）"
  },
  {
    label:"银行付款回单（个人）",
    value:"银行付款回单（个人）"
  },
  {
    label:"购进固定资产",
    value:"购进固定资产"
  },
  {
    label:"增值税专用发票（成本）",
    value:"增值税专用发票（成本）"
  },
  {
    label:"银行利息回单",
    value:"银行利息回单"
  },
  {
    label:"人员工资单",
    value:"人员工资单"
  },
  {
    label:"其他类型",
    value:"其他类型"
  },
  {
    label:"增值税电子发票（收入）",
    value:"增值税电子发票（收入）"
  },
  {
    label:"购进固定资产（专票）",
    value:"购进固定资产（专票）"
  },
  {
    label:"提供服务收入",
    value:"提供服务收入"
  },
  {
    label:"银行收款回单（个人）",
    value:"银行收款回单（个人）"
  },
  {
    label:"增值税电子专用发票（收入）",
    value:"增值税电子专用发票（收入）"
  },
  {
    label:"服务成本（普票）",
    value:"服务成本（普票）"
  },
  {
    label:"增值税费用专票",
    value:"增值税费用专票"
  },
]